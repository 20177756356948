<template>
  <div class="text-left Payment" style="overflow: hidden">
    <div class="row">
      <div class="col-12 MainSection">
        <div>
          <!-- Logo -->
          <div class="px-5">
            <img
              src="/assets/img/new-website/LogoQuizell.svg"
              style="height: 40px; cursor: pointer"
              alt="Quizell"
              class="img-fluid"
              @click="$router.push('/')"
            />
          </div>

            <div  style="min-height: 90vh">
                <div v-if="isLoading">
                    <LoaderComp/>
                </div>
                <div v-else>
                   <div v-if="isSuccess" class="d-flex flex-column flex-grow-1 align-items-center"
            style="height: 90%">
                            <div class="">
              <LazyImage 
  :src="`https://images.quizell.com/website/404.png`" style="height:500px;" :title="`Error 404`" :alt="`Error 404`" class="Img-Fluid"
 />
            </div>
            <div class="TitleDiv">
              <p class="title">Congratulations! The payment was completed.</p>
            </div>
            <div class="mt-3">
              <button
                class="btn Error-Btn px-5 py-3"
                @click="$router.push('/')"
              >
                Back to Home
              </button>
            </div>
                   </div>
                   <div v-else>
                     <div
            class="d-flex flex-column flex-grow-1 align-items-center"
            style="height: 90%"
          >
            <div class="">
              <LazyImage 
  :src="`https://images.quizell.com/website/404.png`" style="height:500px;" :title="`Error 404`" :alt="`Error 404`" class="Img-Fluid"
 />
            </div>
            <div class="TitleDiv">
              <p class="title">Oops! There's something wrong.</p>
            </div>
            <div class="mt-3">
              <button
                class="btn Error-Btn px-5 py-3"
                @click="$router.push('/')"
              >
                Back to Home
              </button>
            </div>
          </div>
                   </div>
                </div>

            </div>

         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import LoaderComp from "../customize/component/LoaderComp.vue";
export default {
    components:{
        LoaderComp
    },
  data() {
    return {
        isLoading:false,
        isSuccess:false
    };
  },
  methods: {
    successScenario(){
        localStorage.removeItem("selected_plan_id")
        this.isSuccess = true;
        
    },
   async sendData(obj){
             this.isLoading = true;

      try {
       let data = obj
       data["plan_id"] = localStorage.getItem("selected_plan_id")
        const response = await axios.post(`/execute/agreement`, data);

        if(response.status == 200){
            this.successScenario();
             setTimeout(
          function () {
            this.$router.push("/")
          }.bind(this),
          2000
        );
        } else{
          this.isSuccess = false;
            setTimeout(
          function () {
            this.$router.push("/")
          }.bind(this),
          2000
        );
        }

        // console.log("Response",response)
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isLoading = false;
      }
    }
  },
   created(){
    if(Object.entries( this.$route.query).length !== 0 ){
        if(this.$route.query){
            this.isLoading = true
            const obj = this.$route.query
           this.sendData(obj)
        }
   
      
    }
  }
};
</script>

<style scoped>
.Payment {
  height: 100vh;
  background-image: url("/assets/img/new-website/home-main-hero2.svg");
  background-color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
}
.Payment .MainSection {
  padding: 2% 5%;
}
.MainSection .TitleDiv {
  padding: 48px;
}
.MainSection .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 136%;

  text-align: center;

  color: #161e34;
}
.Error-Btn {
  background: #4d1b7e;
  border-radius: 100px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}
.Error-Btn:hover {
  background: #ffc000;
  color: #ffffff;
}

@media (max-width: 560px) {
  .MainSection .title {
    font-weight: 500;
    font-size: 26px;
    line-height: 60px;
  }

  .MainSection .TitleDiv {
    padding: 28px;
  }
}
</style>
